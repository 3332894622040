
import TaskManager from "./TaskManger/task";
import auth from "./auth/auth"
import fellowprofile from "./fellowprofile/fellow"
import filter from "./filter/filter";
export default {

  auth: auth,
  TaskManager: TaskManager,
  fellowprofile: fellowprofile,
  filter:filter
};
