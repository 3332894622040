var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "SlideYUpTransition",
    { attrs: { duration: _vm.animationDuration } },
    [
      _c(
        "b-modal",
        {
          ref: "app-modal",
          staticClass: "modal fade",
          attrs: {
            size: _vm.size,
            "hide-header": !_vm.$slots.header,
            "modal-class": [{ "modal-mini": _vm.type === "mini" }].concat(
              _vm.modalClasses
            ),
            tabindex: "-1",
            role: "dialog",
            centered: "",
            "header-class": _vm.headerClasses,
            "footer-class": _vm.footerClasses,
            "content-class": [
              _vm.gradient ? "bg-gradient-" + _vm.gradient : "",
            ].concat(_vm.modalContentClasses),
            "body-class": _vm.bodyClasses,
            "aria-hidden": !_vm.show,
          },
          on: {
            mousedown: function ($event) {
              if ($event.target !== $event.currentTarget) {
                return null
              }
              return _vm.closeModal.apply(null, arguments)
            },
            close: _vm.closeModal,
            hide: _vm.closeModal,
          },
          scopedSlots: _vm._u(
            [
              {
                key: "modal-header",
                fn: function () {
                  return [
                    _vm._t("header"),
                    _vm._t("close-button", function () {
                      return [
                        _vm.showClose
                          ? _c(
                              "button",
                              {
                                staticClass: "close",
                                attrs: {
                                  type: "button",
                                  "data-dismiss": "modal",
                                  "aria-label": "Close",
                                },
                                on: { click: _vm.closeModal },
                              },
                              [
                                _c(
                                  "span",
                                  { attrs: { "aria-hidden": !_vm.show } },
                                  [_vm._v("×")]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    }),
                  ]
                },
                proxy: true,
              },
              {
                key: "modal-footer",
                fn: function () {
                  return [_vm._t("footer")]
                },
                proxy: true,
              },
            ],
            null,
            true
          ),
        },
        [_vm._t("default")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }