import Repository from "../Repository";
// const formdata = { headers: { "Content-Type": "multipart/form-data" } };
const Get = "get";
const Add = "create";
const Update = "update";
const login = "login"


export default {
    getUsers(payload) {
        return Repository.post(transformRoute(Get), payload);
    },
    addUsers(payload) {
        return Repository.post(transformRoute(Add), payload);
    },
    updateUsers(payload) {
        return Repository.post(transformRoute(Update), payload);
    },

    login(payload) {
        return Repository.post(transformRoute(login), payload);
    },

};

const transformRoute = (route) => {
    return `users/${route}`;
};
