var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "custom-control custom-checkbox",
      class: [
        { disabled: _vm.disabled },
        ((_obj = {}), (_obj["custom-checkbox-" + _vm.type] = _vm.type), _obj),
        _vm.inlineClass,
      ],
    },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.model,
            expression: "model",
          },
        ],
        staticClass: "custom-control-input",
        class: _vm.inputClasses,
        attrs: { id: _vm.cbId, type: "checkbox", disabled: _vm.disabled },
        domProps: {
          checked: Array.isArray(_vm.model)
            ? _vm._i(_vm.model, null) > -1
            : _vm.model,
        },
        on: {
          change: function ($event) {
            var $$a = _vm.model,
              $$el = $event.target,
              $$c = $$el.checked ? true : false
            if (Array.isArray($$a)) {
              var $$v = null,
                $$i = _vm._i($$a, $$v)
              if ($$el.checked) {
                $$i < 0 && (_vm.model = $$a.concat([$$v]))
              } else {
                $$i > -1 &&
                  (_vm.model = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
              }
            } else {
              _vm.model = $$c
            }
          },
        },
      }),
      _c(
        "label",
        { staticClass: "custom-control-label", attrs: { for: _vm.cbId } },
        [
          _vm._t("default", function () {
            return [_vm.inline ? _c("span", [_vm._v(" ")]) : _vm._e()]
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }