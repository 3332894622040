export default {
    GET_PROGRAM_AREA(state, payload) {
        state.programarea = payload;
    },
    GET_PARTNER_NGO(state, payload) {
        state.partnerngo = payload;
    },
    GET_BATCH(state, payload) {
        state.batch = payload;
    },
};
