import RepositoryFactory from "@/apis";
const TaskManager = RepositoryFactory.TaskManager;
// const term = RepositoryFactory.term;
// const privacy = RepositoryFactory.privacy;
export default {
  getTask(payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await TaskManager.getTask(payload);
        console.log(data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  addTask(payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await TaskManager.addTask(payload);
        console.log(data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  updateTask(payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await TaskManager.updateTask(payload);
        console.log(data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  getUsers(payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await TaskManager.getUsers(payload);
        console.log(data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
};
