import RepositoryFactory from "@/apis";
const auth = RepositoryFactory.auth;
// const term = RepositoryFactory.term;
// const privacy = RepositoryFactory.privacy;
export default {
  getUsers(payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await auth.getUsers(payload);
        console.log(data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  login({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await auth.login(payload);
        console.log(data);
        commit("LOGIN_USER", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
};
