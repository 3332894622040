import Repository from "../Repository";
// const formdata = { headers: { "Content-Type": "multipart/form-data" } };
const GetProgramArea = "programarea/Get";
const GetPartnerNgo = "partnerngo/Get";
const GetBatch = "batch/Get";

export default {
  GetProgramArea(payload) {
    return Repository.post(transformRoute(GetProgramArea), payload);
  },
  GetPartnerNgo(payload) {
    return Repository.post(transformRoute(GetPartnerNgo), payload);
  },
  GetBatch(payload) {
    return Repository.post(transformRoute(GetBatch), payload);
  },
};

const transformRoute = (route) => {
  return `filter/${route}`;
};
