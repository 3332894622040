export default [
  {
    path: "register",
    name: "register",
    component: () => import("@/views/login/Register.vue"),
  },
  {
    path: "login",
    name: "login",
    component: () => import("@/views/login/Login.vue"),
  },

  {
    path: "forget",
    name: "forget",
    component: () => import("@/views/login/Forget.vue"),
  },
  {
    path: "forget2",
    name: "forget2",
    component: () => import("@/views/login/Forget2.vue"),
  },
];
