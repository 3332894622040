import RepositoryFactory from "@/apis";
const fellowprofile = RepositoryFactory.fellowprofile;
// const term = RepositoryFactory.term;
// const privacy = RepositoryFactory.privacy;
export default {
  GetFellowProfile({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await fellowprofile.GetFellowProfile(payload);
        commit("GET_FELLOW_PROFILE", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  GetSingle({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await fellowprofile.GetSingle(payload);
        commit("GET_SINGLE_FELLOW", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  // ####################### GET _PRESS_RELEASE ####################
  // ####################### GET _PRESS_RELEASE ####################

  GetPressRelease({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await fellowprofile.GetPressRelease(payload);
        commit("GET_PRESS_RELEASE", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  // ####################### SEND_EMAIL ####################
  // ####################### SEND_EMAIL ####################

  SendMail(payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await fellowprofile.SendMail(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
};
