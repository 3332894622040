import Repository from "../Repository";
// const formdata = { headers: { "Content-Type": "multipart/form-data" } };
const GetFellowProfile = "fellows/get";
const GetSingle = "fellows/getsingle";
const getpressrelease="pressrelease/get";
const SendMail="mail/send"
export default {
  GetFellowProfile(payload) {
    return Repository.post(transformRoute(GetFellowProfile), payload);
  },
  GetSingle(payload) {
    return Repository.post(transformRoute(GetSingle), payload);
  },
  GetPressRelease(payload) {
    return Repository.post(transformRoute(getpressrelease), payload);
  },
  SendMail(payload){
    return Repository.post(transformRoute(SendMail),payload)
  }
};

const transformRoute = (route) => {
  return `applicant/${route}`;
};
