var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    {
      staticClass:
        "navbar navbar-vertical  navbar-expand-md navbar-light bg-white sidebar-rem",
      class: _vm.sidebarPosition,
      attrs: { id: "sidenav-main" },
    },
    [
      _c(
        "div",
        {},
        [
          _vm._t("mobile-right", function () {
            return [
              _c(
                "ul",
                { staticClass: "nav align-items-center d-md-none" },
                [
                  _c(
                    "base-dropdown",
                    {
                      staticClass: "nav-item",
                      attrs: {
                        "menu-on-right": "",
                        tag: "li",
                        "title-tag": "a",
                      },
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "nav-link nav-link-icon",
                          attrs: {
                            slot: "title-container",
                            href: "#",
                            role: "button",
                            "aria-haspopup": "true",
                            "aria-expanded": "false",
                          },
                          slot: "title-container",
                        },
                        [_c("i", { staticClass: "ni ni-bell-55" })]
                      ),
                      _c(
                        "a",
                        { staticClass: "dropdown-item", attrs: { href: "#" } },
                        [_vm._v("Action")]
                      ),
                      _c(
                        "a",
                        { staticClass: "dropdown-item", attrs: { href: "#" } },
                        [_vm._v("Another action")]
                      ),
                      _c("div", { staticClass: "dropdown-divider" }),
                      _c(
                        "a",
                        { staticClass: "dropdown-item", attrs: { href: "#" } },
                        [_vm._v("Something else here")]
                      ),
                    ]
                  ),
                  _c(
                    "base-dropdown",
                    {
                      staticClass: "nav-item",
                      attrs: {
                        "menu-on-right": "",
                        tag: "li",
                        "title-tag": "a",
                      },
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "nav-link",
                          attrs: {
                            slot: "title-container",
                            href: "#",
                            role: "button",
                          },
                          slot: "title-container",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "media align-items-center" },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "avatar avatar-sm rounded-circle",
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      alt: "Image placeholder",
                                      src: "img/theme/team-1.jpg",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: " dropdown-header noti-title" },
                        [
                          _c("h6", { staticClass: "text-overflow m-0" }, [
                            _vm._v("Welcome!"),
                          ]),
                        ]
                      ),
                      _c(
                        "router-link",
                        {
                          staticClass: "dropdown-item",
                          attrs: { to: "/profile" },
                        },
                        [
                          _c("i", { staticClass: "ni ni-single-02" }),
                          _c("span", [_vm._v("My profile")]),
                        ]
                      ),
                      _c(
                        "router-link",
                        {
                          staticClass: "dropdown-item",
                          attrs: { to: "/profile" },
                        },
                        [
                          _c("i", { staticClass: "ni ni-settings-gear-65" }),
                          _c("span", [_vm._v("Settings")]),
                        ]
                      ),
                      _c(
                        "router-link",
                        {
                          staticClass: "dropdown-item",
                          attrs: { to: "/profile" },
                        },
                        [
                          _c("i", { staticClass: "ni ni-calendar-grid-58" }),
                          _c("span", [_vm._v("Activity")]),
                        ]
                      ),
                      _c(
                        "router-link",
                        {
                          staticClass: "dropdown-item",
                          attrs: { to: "/profile" },
                        },
                        [
                          _c("i", { staticClass: "ni ni-support-16" }),
                          _c("span", [_vm._v("Support")]),
                        ]
                      ),
                      _c("div", { staticClass: "dropdown-divider" }),
                      _c(
                        "a",
                        { staticClass: "dropdown-item", attrs: { href: "#!" } },
                        [
                          _c("i", { staticClass: "ni ni-user-run" }),
                          _c("span", [_vm._v("Logout")]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          }),
          _vm._t("default"),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }