import axios from "axios";
// You can use your own logic to set your local or production domain
import csencrypt from "../config/csencrypt";

const baseDomain = process.env.VUE_APP_BASE_URL_CORE_API;
const baseURL = `${baseDomain}`;

// const userInfoLocalStorage = localStorage.getItem("token");

const API = axios.create({
  baseURL: baseURL,
  headers: {
    // "project-suite": "Ecommerce",
    "community-name": process.env.VUE_APP_COMMUNITY_NAME,
  },
});

API.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (config.data) {
      config.data = {
        data:
          process.env.enable_encription != 0
            ? csencrypt.Encript(JSON.stringify(config.data))
            : JSON.stringify(config.data),
      };
    }
    if (token) {
      config.headers.authorization = `Bearer ${token}`;
    }
    return config;
  },

  (error) => Promise.reject(error)
);
API.interceptors.response.use(
  (response) => {
    if (response) {
      if (Object.keys(response.data.data).length) {
        response.data.data = csencrypt.Decrypt(response.data.data);
      }
    }
    return response;
  },
  (error) => Promise.reject(error)
);

export default API;
