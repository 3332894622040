import Repository from "../Repository";
// const formdata = { headers: { "Content-Type": "multipart/form-data" } };
const Get = "get";
const Add = "create";
const Update = "update";
const getUsers = "getUsers";

export default {
  getTask(payload) {
    return Repository.post(transformRoute(Get), payload);
  },
  addTask(payload) {
    return Repository.post(transformRoute(Add), payload);
  },
  updateTask(payload) {
    return Repository.post(transformRoute(Update), payload);
  },
  getUsers(payload) {
    return Repository.post(transformRoute(getUsers), payload);
  },
};

const transformRoute = (route) => {
  return `task-manager/${route}`;
};
