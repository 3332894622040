import RepositoryFactory from "@/apis";
const filter = RepositoryFactory.filter;

export default {


  GetProgramArea({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await filter.GetProgramArea(payload);
        commit("GET_PROGRAM_AREA",data.data)
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  GetPartnerNgo({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await filter.GetPartnerNgo(payload);
        commit("GET_PARTNER_NGO",data.data)
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  GetBatch({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await filter.GetBatch(payload);
        commit("GET_BATCH",data.data)
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

};
