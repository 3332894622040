import Vue from "vue";
import router from "@/router";
import App from "./App.vue";
import "@/assets/scss/Index.scss";
// import VuePageTransition from "vue-page-transition";
import ElementUI from "element-ui";
Vue.use(ElementUI);
import "element-ui/lib/theme-chalk/index.css";
import AOS from "aos";
// import VueTypedJs from "vue-typed-js";
// Vue.use(VueTypedJs);
import "aos/dist/aos.css"; // You can also use <link> for styles
// ..
import "@/assets/scss/argon.scss";
AOS.init();
// Vue.use(VuePageTransition);
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import VueFormWizard from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
Vue.use(VueFormWizard);

// import "bootstrap/dist/css/bootstrap.css";
// import "bootstrap-vue/dist/bootstrap-vue.css";

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Vue.use(BVToastPlugin);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
import Vuesax from "vuesax";
import DashboardPlugin from "./plugins/dashboard-plugin";
import VueMeta from "vue-meta";
Vue.use(VueMeta);

import FeatherIcon from "@/components/FeatherIcon.vue";
Vue.component(FeatherIcon.name, FeatherIcon);
Vue.use(DashboardPlugin);
// Vue.use(VueMaterial);
// import VueQuillEditor from "vue-quill-editor";

// import "quill/dist/quill.core.css"; // import styles
// import "quill/dist/quill.snow.css"; // for snow theme
// import "quill/dist/quill.bubble.css"; // for bubble theme
// Google Maps
import * as VueGoogleMaps from "vue2-google-maps";

//vue sweetalert2
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
Vue.use(VueSweetalert2);

Vue.use(VueGoogleMaps, {
  load: {
    // Add your API key here
    key: "AIzaSyC8mc5Hz6MqbLb9BbnA1lGrXGaWAAEPrFw",
    libraries: "places",
  },
});
import VueMoment from "vue-moment";
import moment from "moment-timezone";
Vue.use(VueMoment, {
  moment,
});
// Vue.use(VueQuillEditor /* { default global options } */);
import "vuesax/dist/vuesax.css"; //Vuesax styles
Vue.use(Vuesax, {
  // options here
});
require("vue2-animate/dist/vue2-animate.min.css");
// Vue.use(VueSweetalert2);
import "./assets/scss/tailwind.css";
Vue.config.productionTip = false;
if (process.env.NODE_ENV !== "development") {
  console.log = () => {};
  console.warn = () => {};
  console.error = () => {};
  console.info = () => {};
  console.debug = () => {};
}
// Vuex Store
import store from "./store/index.js";
Vue.prototype.$imageURL = process.env.VUE_APP_CLOUD_STORAGE_URL;
new Vue({
  store,
  router,
  Vuesax,
  render: (h) => h(App),
}).$mount("#app");
