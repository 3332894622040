import Vue from "vue";
import VueRouter from "vue-router";
import RegisterRouter from "@/views/login/routes/Index.js";

Vue.use(VueRouter);
const router = new VueRouter({
  mode: "history",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "main",
      component: () => import("@/main/Main.vue"),
      redirect: "home",
      children: [
        {
          path: "/",
          name: "home",
          component: () => import("@/views/website/home/Home.vue"),
        },
        {
          path: "/home",
          name: "home",
          component: () => import("@/views/website/home/Home.vue"),
        },
        {
          path: "/pr",
          name: "pr",
          component: () => import("@/views/website/home/Home.vue"),
        },
        {
          path: "/register",
          name: "register",
          component: () => import("@/views/website/home/Home.vue"),
        },
        {
          path: "/SBI",
          name: "SBI",
          component: () => import("@/views/website/home/Home.vue"),
        },
        {
          path: "/mava",
          name: "mava",
          component: () => import("@/views/website/home/Home.vue"),
        },
        {
          path: "/apply",
          name: "apply",
          component: () => import("@/views/website/home/Home.vue"),
        },
        {
          path: "faq",
          name: "faq",
          component: () => import("@/views/website/faq/Faq.vue"),
        },
        {
          path: "about",
          name: "about",
          component: () => import("@/views/website/about/About.vue"),
        },
        {
          path: "partner",
          name: "partner",
          component: () => import("@/views/website/partner/Partner.vue"),
        },
        {
          path: "programe",
          name: "programe",
          component: () => import("@/views/website/programe/Programe.vue"),
        },
        {
          path: "profile",
          name: "profile",
          component: () => import("@/views/website/profile/Profile.vue"),
        },
        {
          path: "application",
          name: "application",
          component: () =>
            import("@/views/website/application/Application.vue"),
        },
        {
          path: "join",
          name: "join",
          component: () => import("@/views/website/join/Join.vue"),
        },
        {
          path: "pressrelease",
          name: "blog",
          component: () => import("@/views/website/bolgs/Blog.vue"),
        },
        {
          path: "overview",
          name: "overview",
          component: () =>
            import("@/views/website/bolgs/components/Overview.vue"),
        },
        {
          path: "press",
          name: "press",
          component: () => import("@/views/website/press/Press.vue"),
        },
        {
          path: "project",
          name: "project",
          component: () => import("@/views/website/project/Project.vue"),
        },
        {
          path: "news",
          name: "news",
          component: () => import("@/views/website/newsletter/Newsletter.vue"),
        },

        {
          path: "Profiledetail/:name/:id",
          name: "Profiledetail",
          component: () =>
            import("@/views/website/profile/components/Popup.vue"),
          meta: {
            hideNavbar: true,
          },
        },
        {
          path: "/:pathMatch(.*)*",
          name: "error",
          component: () => import("@/views/website/error/Error.vue"),
          meta: {
            hideNavbar: true,
          },
        },
      ],
    },

    //fullpage router
    {
      path: "",
      component: () => import("@/main/FullPage.vue"),

      children: [...RegisterRouter],
    },
  ],
});
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});
export default router;
