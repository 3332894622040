import CryptoJS from "crypto-js";
const Encript = (plainData) => {
  if (parseInt(process.env.enable_encription) == 0) return plainData;

  let randomIV = randomString(16);

  let key = CryptoJS.enc.Utf8.parse("$P@mOu$0172@0r!P");

  let iv = CryptoJS.enc.Utf8.parse(randomIV);

  let encripted = CryptoJS.AES.encrypt(JSON.stringify(plainData), key, {
    keySize: 128 / 8,
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  encripted = encripted.toString() + randomIV;

  return encripted.replace(/\\/g, "/");
};

const Decrypt = (cipherData) => {
  console.log(cipherData, "cipherDatacipherData");
  let newcipherdata = cipherData;
  if (cipherData.data) {
    newcipherdata = cipherData.data;
  }
  if (parseInt(process.env.enable_encription) == 0) return newcipherdata;
  var key = CryptoJS.enc.Utf8.parse("$P@mOu$0172@0r!P");

  var iv = CryptoJS.enc.Utf8.parse(
    newcipherdata.slice(newcipherdata.length - 16)
  );

  newcipherdata = newcipherdata.slice(0, newcipherdata.length - 16);
  var decrypted = CryptoJS.AES.decrypt(newcipherdata, key, {
    keySize: 128 / 8,
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  let Jsondata = JSON.parse(` ${decrypted.toString(CryptoJS.enc.Utf8)}`);
  console.log(decrypted, "JsondataJsondata");
  return Jsondata;
};

const randomString = (length) => {
  let text = "";
  let possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  return text;
};

export default { Encript, Decrypt };
