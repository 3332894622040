var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "alert alert-notify alert-dismissible",
      class: [
        { "alert-with-icon": _vm.icon },
        _vm.verticalAlign,
        _vm.horizontalAlign,
        _vm.alertType,
      ],
      style: _vm.customPosition,
      attrs: {
        "data-notify": "container",
        role: "alert",
        "data-notify-position": "top-center",
      },
      on: { click: _vm.tryClose },
    },
    [
      _vm.icon || _vm.$slots.icon
        ? [
            _vm._t("icon", function () {
              return [
                _c(
                  "span",
                  {
                    staticClass: "alert-icon",
                    attrs: { "data-notify": "icon" },
                  },
                  [_c("i", { class: _vm.icon })]
                ),
              ]
            }),
          ]
        : _vm._e(),
      _c(
        "span",
        { staticClass: "alert-text" },
        [
          _vm.title
            ? _c("span", { staticClass: "title" }, [
                _c("b", [_vm._v(_vm._s(_vm.title)), _c("br")]),
              ])
            : _vm._e(),
          _vm.message
            ? _c("span", { domProps: { innerHTML: _vm._s(_vm.message) } })
            : _vm._e(),
          !_vm.message && _vm.component
            ? _c("content-render", { attrs: { component: _vm.component } })
            : _vm._e(),
        ],
        1
      ),
      _vm._t("dismiss-icon", function () {
        return [
          _c(
            "button",
            {
              staticClass: "close",
              attrs: {
                type: "button",
                "data-dismiss": "alert",
                "aria-label": "Close",
              },
              on: { click: _vm.close },
            },
            [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
          ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }